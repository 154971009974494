import sav_head from './sav_head.svg';
import jasper_head from './jasper_head.svg';
import mom_head from './mom_head.svg';
import cyrus_head from './cyrus_head.svg';
import ruby_head from './ruby_head.svg';
import dad_head from './dad_head.svg';
import family_pic from './family_pic.svg';
import julerrio_head from './julerrio_head.svg';

import './HeadPages.css';

function HeadPages() {
  switch (window.location.hostname) {
    case "savannahswensen.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={sav_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "jasperswensen.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={jasper_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "emilymabey.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={mom_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "cyrusswensen.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={cyrus_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "rubyswensen.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={ruby_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "stevepswensen.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={dad_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "swensen5.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={family_pic} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "julerrioswensen.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={julerrio_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
    case "julerriomcgruder.com":
      return (
        <div className="App">
          <header className="App-header">
            <img src={julerrio_head} className="App-logo" alt="logo" />
            <p>
              {window.location.hostname}
            </p>
          </header>
        </div>
      );
      break;
}
}

export default HeadPages;
