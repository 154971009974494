import { Routes, Route } from 'react-router-dom';
import HeadPages from './HeadPages';
import Favicons from './Favicons';

const App = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<HeadPages />} />
          {/* <Route path="/favicon.ico" element={<Favicons />} /> */}
       </Routes>
    </>
 );
};

export default App;