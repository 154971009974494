//import sav_ico from './sav_logos/favicon.ico';
import jasper_ico from './logo.svg';


function Favicons() {
  switch (window.location.hostname) {
    case "localhost":
      return (
        <img src={jasper_ico} className="App-logo" alt="logo" />
      );
      break;
    case "jasperswensen.com":
      return (
        <img src={jasper_ico} className="App-logo" alt="logo" />
      );
      break;
  }
}

export default Favicons;
